import { shallowMount } from '@vue/test-utils';
import Home from '@/pages/home';

const gameList = [
  {
    id: 'id',
    preview: 'preview',
    text: 'text',
    title: 'title',
  },
  {
    id: 'id',
    preview: 'preview',
    text: 'text',
    title: 'title',
  },
];
const route = 'route';
const mockPush = jest.fn(() => route);

jest.mock('vue-router', () => ({
  useRouter: () => ({
    push: mockPush,
  }),
}));

const store = {
  dispatch: jest.fn(),
};

describe('Home.vue', () => {
  const global = {
    provide: {
      store,
    },
    mocks: {
      gameList,
    },
  };

  it('renders game cards', () => {
    const wrapper = shallowMount(Home, { global });

    expect(wrapper.findAllComponents({ name: 'GameCard' })).toHaveLength(gameList.length);
  });

  it('has h1 and h2', () => {
    const wrapper = shallowMount(Home, { global });

    expect(wrapper.find('h1').element).toBeDefined();
    expect(wrapper.find('h2').element).toBeDefined();
  });

  it('sets game on click', async() => {
    const wrapper = shallowMount(Home, { global });

    await wrapper.findComponent({ name: 'GameCard' }).trigger('click');

    expect(store.dispatch).toBeCalledTimes(1);
    expect(mockPush).toBeCalledTimes(1);
    expect(mockPush).toReturnWith(route);
  });
});
